import React from 'react'

import logo from '../images/cf_logo.webp'

const Footer = () => {
    return (
        <>

            <div className='bg-[#030712]'>
                <div class="text-center pb-6 font-[poppins]">
                    <div href="" class="flex items-center justify-center mb-6 md:mb-10 text-2xl font-semibold text-gray-900">
                    
                           <img src={logo} className='object-cover w-48 md:w-60' alt="" />

                    </div>

                    <div>
                        <p className=' text-[12px] md:text-[14px] font-semibold mb-3 text-white'>BigBay Cinema Factory Pvt Ltd</p>
                    </div>

                    <span class="block text-[10px] md:text-[12px] text-center text-gray-200">©  Vishnu Ads and Ventures 2024. All Rights Reserved.  </span>

                </div>
            </div>

        </>
    )
}

export default Footer