import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'


import background from '../images/background.jpg'

import Time from '../components/Time'
import man from '../images/menTwo.png'

import contact from '../images/contact.png'

import about from '../images/about.png'


import one from '../images/webinar1.png'
import two from '../images/webinar2.png'
import three from '../images/webinar3.png'
import four from '../images/webinar4.png'
import { Link } from 'react-router-dom'



const Home = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])


    return (
        <div className='overflow-hidden'>

            <div className='fixed top-0 h-screen -z-20 w-full '>
                <img src={background} className='w-full object-cover h-screen' alt="" />
            </div>

            {/* -------------- header section ---------------- */}

            <section className='font-[poppins] bg-gradient-to-t from-[#95c4f4]  to-gray-950'>

                <div className=' w-ull  mx-auto'>

                    <div className='flex flex-col-reverse md:flex-row items-start gap-y-3 md:gap-y-12 justify-between '>

                        <div className=' w-full md:w-[50%] px-4 md:px-10  mt-8 md:mt-16 mx-auto'>

                            <div>
                                <div className=' mb-4 md:mb-10'>
                                    <h2 className='font-semibold  text-[20px] md:text-[40px] mb-2 md:mb-8  font-[Oxanium] text-white' data-aos='fade-right' data-aos-duration='1000'>VFX Career Insights - Discover VFX Secrets From An Industry Expert</h2>
                                    <h2 className='font-normal text-[16px] md:text-[30px] font-[roboto] text-gray-100' data-aos='fade-right' data-aos-duration='2000'> Aravind Naga | VFX Supervisor  </h2>
                                </div>

                                <div className='mb-4 md:mb-6 ml-4' data-aos='fade-right' data-aos-duration='2000'>
                                    <ul className='text-[12px] md:text-[16px] font-normal text-gray-100 flex flex-col gap-y-1 list-disc'>
                                        <li>Experience: Over 10+ years in visual effects and post-production</li>
                                        <li>Directorial Work: Directed 20+ AD's & Social Media Content for Chennai Super Kings</li>
                                        <li>Pursuing a Ph.D. on : Generative AI in the field of filmmaking and its Ethical Usage.</li>
                                        <li>Jury member for Animaker Inc's International Animated Video and Designs Award</li>
                                        <li>Jury member for FICCI Best Animated Frames Awards</li>
                                      
                                    </ul>
                                </div>

                                <p className='text-[12px] md:text-[14px] font-normal text-gray-100 mb-4 md:mb-6' data-aos='fade-right' data-aos-duration='3000'><span className='font-bold  white'>Awards :</span>  Osaka Tamil International Film Festival: Best VFX Award for "Super Deluxe" , 4 Madras Advertising Club Awards for advertising campaigns brands such as Flipkart.   </p>
                                <p className='text-[12px] md:text-[14px] font-normal text-gray-100 mb-4 md:mb-6' data-aos='fade-right' data-aos-duration='3000'><span className='font-bold  white'>Filmography :</span> Super Deluxe | Demonte Colony | Modern Love Chennai , Theri | Mersal | Maara | Maragadha Naanayam | Thegidi , Kuthiraivaal | 96 | Kalyana Samayal Saadham | Kallachirippu | Kaiyum Kalavum   </p>

                                <div className='flex items-center gap-x-2 md:gap-x-6 ' data-aos='fade-up' data-aos-duration='2000'>

                                    <div>
                                        <Link to='https://rzp.io/l/vfxwebinar ' target='_blank'>
                                            <p className='border-2 border-gray-600 bg-sky-600  outline outline-offset-2 md:outline-offset-4 outline-1 shadow-md md:shadow-lg drop-shadow-lg shadow-gray-400 rounded-lg px-2 md:px-4 py-1.5 md:py-3 font-semibold text-white font-[roboto] text-[12px] md:text-[16px]' >Webinar Fee: ₹199/-</p>
                                        </Link>
                                    </div>

                                    <div className='-mt-4'>
                                        <Time />
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className=' w-full md:w-[50%] mx-auto flex justify-center'>

                            <div className='w-full  flex justify-center'>
                                <img src={man} className='object-cover rounded-b-md  w-full' alt="" />
                            </div>

                        </div>
                    </div>


                </div>

            </section>


            {/* ----------------------- about vfx -------------------------- */}

            <section className='pt-8 md:pt-20 pb-8 md:pb-20 bg-gradient-to-b from-[#95c4f4] to-white/90 font-[poppins]'>
                <div className=' w-full px-4 md:w-[80%] mx-auto'>

                    <div className='flex  flex-col md:flex-row items-center  gap-y-8 '>

                        <div className='w-full md:w-[50%] mx-auto flex justify-center items-center' data-aos='fade-right' data-aos-duration='2000'>

                            <div className='flex justify-center'>
                                <img src={about} className=' w-full md:w-[80%] drop-shadow-lg object-cover' alt="" />
                            </div>

                        </div>

                        <div className=' w-full md:w-[50%] mx-auto flex justify-center items-center' data-aos='fade-left' data-aos-duration='2000'>
                            <div>

                                <h3 className='text-center font-semibold capitalize text-[14px] md:text-[24px] mb-4 md:mb-8 font-[Oxanium]'  >How This Webinar Can Transform Your Career Path</h3>


                                <div>
                                    <p className='text-[12px] md:text-[16px] font-[roboto] text-center'> Join with our expert to uncover industry insights from his extensive experience working on films and ads in Kollywood. Get the inside scoop on what it takes to succeed in the vibrant world of Tamil cinema!</p>
                                </div>

                                {/* <div>
                                    <ul className='flex flex-col list-disc ml-4 text-[12px] md:text-[14px] font-[roboto] gap-y-2 md:gap-y-4'>
                                        <li>  The role of visual effects in the film industry</li>
                                        <li>Showcase of stunning VFX sequences from popular movies</li>
                                        <li>A edited video showcasing - Industry directors and editors talking on the importance of VFX, latest techniques used and about unreal engine. </li>
                                        <li>Types of VFX shots and the softwares and techniques used to work on them. Showcased with examples from famous films. BTS and working screen captures. </li>
                                    </ul>
                                </div> */}
                            </div>


                        </div>

                    </div>

                </div>
            </section>


            {/* -------------------- topics covered ----------------- */}

            <section className='bg-gradient-to-b from-white/90 to-indigo-400 pt-8 md:pt-20 pb-8 md:pb-20 font-[poppins]'>
                <div className='w-full px-4  md:w-[90%] mx-auto'>

                    <div className='flex justify-center items-center'>

                        <h3 className='font-semibold text-[16px] md:text-[24px] uppercase font-[Oxanium] tracking-[2px] md:tracking-[3px] drop-shadow-lg' data-aos='fade-up' data-aos-duration='1000'>What You Will Learn</h3>

                    </div>

                    <div className='flex justify-center items-center'>


                        <div className='grid  grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-4 mt-8 md:mt-14 '>

                            <div className='bg-gray-50 px-3 py-4 shadow-md drop-shadow-md rounded-lg ' data-aos='fade-up' data-aos-duration='1000'>

                                <div className='flex flex-col items-center gap-y-3 '>
                                    <h3 className='text-center font-semibold text-[14px] md:text-[16px]'>What is Visual Effects (VFX)?</h3>

                                    <p className='font-[roboto] text-[12px] md:text-[14px] text-center'>Understand the fundamentals of VFX and its crucial role in film production.</p>
                                </div>

                            </div>
                            <div className='bg-gray-50 px-3 py-4 shadow-md drop-shadow-md rounded-lg ' data-aos='fade-up' data-aos-duration='2000'>

                                <div className='flex flex-col items-center gap-y-3 '>
                                    <h3 className='text-center font-semibold text-[14px] md:text-[16px]'>Different VFX Departments & Career Opportunities</h3>

                                    <p className='font-[roboto]  text-[12px] md:text-[14px] text-center'>Explore the essential skills and advanced software required in today's VFX industry, both in Hollywood and Domestic Studios.</p>
                                </div>

                            </div>
                            <div className='bg-gray-50 px-3 py-4 shadow-md drop-shadow-md rounded-lg ' data-aos='fade-up' data-aos-duration='3000'>

                                <div className='flex flex-col items-center gap-y-3 '>
                                    <h3 className='text-center font-semibold text-[14px] md:text-[16px]'>The Future of the VFX Career in India</h3>

                                    <p className='font-[roboto]  text-[12px] md:text-[14px] text-center'>Discover the career opportunities, about the top-hired VFX departments, and the future outlook for VFX in India with Virtual Production.</p>
                                </div>

                            </div>
                            <div className='bg-gray-50 px-3 py-4 shadow-md drop-shadow-md rounded-lg ' data-aos='fade-up' data-aos-duration='3000'>

                                <div className='flex flex-col items-center gap-y-3 '>
                                    <h3 className='text-center font-semibold text-[14px] md:text-[16px]'>1-on-1 Doubt Clearing Session</h3>

                                    <p className='font-[roboto]  text-[12px] md:text-[14px] text-center'>Engage in a personalized doubt-clearing session with our expert to address your queries and help secure your future-proof career in VFX.</p>
                                </div>

                            </div>

                        </div>
                    </div>


                    {/* <div className='flex justify-center items-center mt-10'>

                        <div className='grid grid-cols-3 md:grid-cols-5 gap-x-2 gap-y-6 md:gap-x-16 md:gap-y-8 text-[9px] md:text-[14px]'>

                            <div className='flex justify-center items-center ' data-aos='fade-down-left' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200   font-semibold '>2D Tracking</span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-left' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>3D Tracking</span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-left' data-aos-duration='2000'>
                                <span className=' px-4 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>Face Tracking</span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-left' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>De-Aging</span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-left' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>Matte Painting</span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-right' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>3D Modelling </span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-right' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>Composting  </span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-right' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>FX and Dynamics </span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-right' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>Motion Graphics </span>
                            </div>

                            <div className='flex justify-center items-center ' data-aos='fade-down-right' data-aos-duration='2000'>
                                <span className=' px-2 md:px-6 py-3 md:py-4 rounded-lg text-center border md:border-2 border-black/50  bg-gray-200  font-semibold '>Virtual Production </span>
                            </div>

                        </div>

                    </div> */}

                </div>
            </section>


            {/* -------------------------- career ----------------- */}
{/* 
            <section className='bg-gradient-to-b from-indigo-400 to-indigo-600 pt-8  md:pt-20 pb-8 md:pb-20 font-[poppins]'>
                <div className='w-full px-4 md:w-[80%] mx-auto'>

                    <div>
                        <h3 className='uppercase font-semibold text-[16px] md:text-[28px] texgray-gray-400 text-center  font-[Oxanium] tracking-[1px] md:tracking-[3px] ' data-aos='fade-up' data-aos-duration='2000'>scope of the vfx</h3>

                        <p className='text-[12px] md:text-[16px] text-center mt-2 md:mt-4' data-aos='fade-up' data-aos-duration='3000'>Mentor explains about other career avenues like</p>

                    </div>

                    <div className='flex justify-center items-center mt-8 md:mt-16'>

                        <div className='grid grid-cols-2 md:grid-cols-4 gap-y-4 gap-x-2 md:gap-y-6 md:gap-x-10'>

                            <div data-aos='fade-up' data-aos-duration='1000'>
                                <img src={one} className=' w-[70%] md:w-[80%] hover:animate-bounce drop-shadow-xl mx-auto' alt="" />
                            </div>

                            <div data-aos='fade-up' data-aos-duration='2000'>
                                <img src={two} className=' w-[70%] md:w-[80%] hover:animate-bounce drop-shadow-xl mx-auto' alt="" />
                            </div>

                            <div data-aos='fade-up' data-aos-duration='3000'>
                                <img src={three} className=' w-[70%] md:w-[80%] hover:animate-bounce drop-shadow-xl mx-auto' alt="" />
                            </div>

                            <div data-aos='fade-up' data-aos-duration='3000'>
                                <img src={four} className=' w-[70%] md:w-[80%] hover:animate-bounce drop-shadow-xl mx-auto' alt="" />
                            </div>

                        </div>

                    </div>

                </div>
            </section> */}



            {/* -------------------------- contact ---------------------- */}

            <section className='relative pt-8 md:pt-20 pb-14 md:pb-20 bg-gradient-to-b from-indigo-500 to-gray-900 font-[poppins]  ' id='contact' >

                <div className=' w-full md:w-[80%] mx-auto'>

                    <div className='px-4 md:px-0'>
                        <h3 className='font-bold text-[14px] text-white  md:text-[28px] text-center font-[Oxanium]'>Join Our Webinar</h3>
                        <p className='text-[11px] md:text-[14px] mt-3 text-gray-300 text-center'>We're here to assist you! For any inquiries or further information, feel free to reach out</p>
                    </div>

                    <div className='flex flex-col md:flex-row  items-start gap-y-10 mt-6 md:mt-16'>

                        <div className=' w-full md:w-[50%] mx-auto'>

                            <div>
                                <img src={contact} className='w-full object-cover md:rounded-t-md' alt="" />
                            </div>

                        </div>

                        <div className=' w-full px-4  md:w-[50%] mx-auto z-20'>

                            <div>
                                <div class="flex items-center justify-center md:px-16">

                                    <div class="mx-auto w-full">

                                        {/* <div class="mb-5">
                                            <label for="name" class="mb-3 block  font-medium text-white drop-shadow-xl  text-[12px] md:text-[16px] font-[Oxanium]">
                                                Full Name
                                            </label>
                                            <input type="text" name="name" id="name" placeholder="Full Name"
                                                class="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-gray-100 py-2 md:py-3 px-4  font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="email" class="mb-3 block  font-medium text-white drop-shadow-xl  text-[12px] md:text-[16px] font-[Oxanium]">
                                                Email Address
                                            </label>
                                            <input type="email" name="email" id="email" placeholder="example@domain.com"
                                                class="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-gray-100 py-2 md:py-3 px-4  font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="subject" class="mb-3 block  font-medium text-white drop-shadow-xl  text-[12px] md:text-[16px] font-[Oxanium]">
                                                Subject
                                            </label>
                                            <input type="text" name="subject" id="subject" placeholder="Enter your subject"
                                                class="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-gray-100 py-2 md:py-3 px-4  font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                        </div>
                                        <div class="mb-5">
                                            <label for="message" class="mb-3 block  font-medium text-white drop-shadow-xl  text-[12px] md:text-[16px] font-[Oxanium]">
                                                Message
                                            </label>
                                            <textarea rows="4" name="message" id="message" placeholder="Type your message"
                                                class="text-[12px] md:text-[14px] w-full resize-none rounded-md border border-[#e0e0e0] bg-gray-100 py-2 md:py-3 px-4  font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"></textarea>
                                        </div> */}


                                        <div>
                                            <p className='font-semibold text-[16px] md:text-[18px] leading-7 font-[roboto] text-gray-200 text-center mb-4 md:mb-8'>For any questions or additional information, feel free to reach out to us. Our team is here to help you take the next step in your VFX career.</p>
                                        </div>

                                        <div className=' mt-6 md:mt-10'>
                                            <Link to='https://rzp.io/l/vfxwebinar' target='_blank' >
                                                <button
                                                    class="font-[roboto] text-[14px] md:text-[16px] hover:shadow hover:scale-105 duration-300  rounded-md bg-sky-900 hover:bg-sky-800 border border-white  py-2 md:py-3 px-8  w-full font-semibold text-white outline-none ">
                                                    Pay Now ₹199 + GST
                                                </button>

                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>

                <div className='absolute bottom-0 w-full  '>

                    <div>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,64L26.7,80C53.3,96,107,128,160,128C213.3,128,267,96,320,117.3C373.3,139,427,213,480,224C533.3,235,587,181,640,170.7C693.3,160,747,192,800,192C853.3,192,907,160,960,165.3C1013.3,171,1067,213,1120,218.7C1173.3,224,1227,192,1280,186.7C1333.3,181,1387,203,1413,213.3L1440,224L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path></svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#030712" fill-opacity="1" d="M0,64L48,74.7C96,85,192,107,288,101.3C384,96,480,64,576,80C672,96,768,160,864,170.7C960,181,1056,139,1152,133.3C1248,128,1344,160,1392,176L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>

                    </div>

                </div>

            </section>

        </div>
    )
}

export default Home
